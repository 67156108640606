import React from "react";
import CarParkTable from "../../components/tables/CarParkTable";
import PageTitle from "../../components/atoms/PageTitle";
import { useSelector } from "react-redux";
import AddButton from "../../components/atoms/AddButton";

const CarPark = () => {
  const { currentUser } = useSelector((state) => state);

  return (
    <div>
      <PageTitle> Car Park </PageTitle>
      {currentUser &&
        (currentUser.role === "master" || currentUser.role === "owner") && (
          <AddButton />
        )}
      <br />
      <br />
      <CarParkTable />
    </div>
  );
};

export default CarPark;
