import React from "react";
import PageTitle from "../../components/atoms/PageTitle";

const Pricing = () => {
  return (
    <div>
      <PageTitle> Pricing </PageTitle>
      <h3>Contact Us for free quote on 020 8567 3451</h3>
      <br/>
      <h4>£££..</h4>
    </div>
  );
};

export default Pricing;