import { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "../Button";
import { StyledForm } from "../../forms/styled";
import FormElement from "../FormElement";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { StyledFormControl } from "./StyledEditCarPk";
import { editParkingFormElements } from "../../forms/formElementsData";
import {
  usersCollection,
  parkingsCollection,
} from "../../../firebase/firestoreUtils";

const EditCarPkIcon = ({ parking }) => {
  const [users, setUsers] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    usersCollection.get().then((response) => {
      const users = response.docs.map((el) => {
        return { ...el.data(), id: el.id };
      });

      setAllUsers(users);
      setUsers(
        users.filter(
          (el) =>
            el.selectedParkingSpace === parking.id &&
            (el.role === "user" || el.role === "admin")
        )
      );
    });
  }, [parking.id]);

  return (
    <Formik
      initialValues={{
        admin: parking?.adminId,
        parkingName: parking?.parkingName,
        companyName: parking?.companyName,
      }}
      onSubmit={(values, { resetForm }) => {
        parkingsCollection
          .doc(parking.id)
          .set({
            ...parking,
            adminId: values.admin,
            parkingName: values.parkingName,
            companyName: values.companyName,
          })
          .then((res) => {
            if (parking.adminId) {
              const admin = allUsers.find(
                (el) => el.userId === parking.adminId
              );
              usersCollection.doc(admin.id).set({
                ...admin,
                role: "user",
              });

              const user = users.find((el) => el.id === values.admin);
              usersCollection
                .doc(user.userId)
                .set({
                  ...user,
                  role: "admin",
                })
                .then((res) => window.location.reload());
            }
          });
      }}
    >
      {(props) => (
        <StyledForm>
          {editParkingFormElements.map((el) => (
            <FormElement
              inputName={el.inputName}
              inputType={el.inputType}
              labelContent={el.labelContent}
            />
          ))}
          <br />
          {/* ______________EDIT - selecting admin: _____________________________________________ */}
          <StyledFormControl>
            <InputLabel id="demo-simple-select-label">Select admin</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Start time"
              required
              name="admin"
              value={props.values.admin}
              onChange={props.handleChange}
            >
              {users.map((el) => (
                <MenuItem value={el.id}>
                  {el.firstName} {el.surname}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          {/* ______________________________________________________________________________________ */}
          <br />
          <Button type="submit">Save</Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default EditCarPkIcon;
