import React from "react";
import { Formik } from "formik";
import Button from "../../atoms/Button";
import { accountFormElements } from "../formElementsData";
import FormElement from "../../atoms/FormElement";
import { StyledForm } from "../styled";
import { accountFormValidationSchema } from "../../../utils/validationSchema";
import { useSelector } from "react-redux";
import { usersCollection } from "../../../firebase/firestoreUtils";

const AccountForm = () => {
  const { currentUser, selectedParkingSpace } = useSelector((state) => state);

  return (
    <Formik
      initialValues={{
        firstName: currentUser?.firstName,
        surname: currentUser?.surname,
        email: currentUser?.email,
        userGroups: selectedParkingSpace?.parkingName,
        roles: currentUser?.role,
        activeVehicleRegistration: currentUser?.vehicleRegistration,
      }}
      validationSchema={accountFormValidationSchema}
      onSubmit={(values, { resetForm }) => {
        usersCollection
          .doc(currentUser?.userId)
          .set({
            ...currentUser,
            firstName: values.firstName,
            surname: values.surname,
            vehicleRegistration: values.activeVehicleRegistration,
          })
          .then((res) => {
            window.location.reload();
          })
          .catch((err) => console.log(err));

        // resetForm();
      }}
    >
      {() => (
        <StyledForm>
          {accountFormElements.map((el) => (
            <FormElement
              key={el.inputName}
              inputName={el.inputName}
              inputType={el.inputType}
              labelContent={el.labelContent}
              isDisabled={el.disabled}
            />
          ))}

          <Button type="submit">Update</Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default AccountForm;
