import React from "react";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { FaListAlt } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";
import Heading from "../../components/atoms/Heading";
import { Item, ItemWrapper } from "./styles/StyledAboutSectionWithIcons";

const sectionData = [
  {
    icon: <LeaderboardIcon style={{ fontSize: "40px" }} />,
    title: "Self Service",
    content:
      "Describe a key benefit that your product provides for your customers – and explain the impact it can have.",
  },
  {
    icon: <FaListAlt />,
    title: "Automated Enforcement",
    content:
      "Describe a key benefit that your product provides for your customers – and explain the impact it can have.",
  },
  {
    icon: <BsCheckCircleFill />,
    title: "Easy to Use",
    content:
      "Clear and simple process for the users of your car park to book spaces at any time.",
  },
];

const AboutSectionWithIcons = () => {
  return (
    <ItemWrapper>
      {sectionData.map(({ icon, title, content }, idx) => (
        <Item key={idx}>
          <span className="icon">{icon}</span>
          <Heading headingType="h3">{title}</Heading>
          <p className="content">{content}</p>
        </Item>
      ))}
    </ItemWrapper>
  );
};

export default AboutSectionWithIcons;
