import React, { useState } from "react";
import RequestsTable from "../../components/tables/RequestsTable";
import RequestsTableOwners from "../../components/tables/RequestsTableOwners";
import PageTitle from "../../components/atoms/PageTitle";
import Button from "../../components/atoms/Button";
import { mainTheme } from "../../globalStyles/themes/mainTheme";

const Requests = () => {
  const [currentView, setCurrentView] = useState("users");

  return (
    <div>
      <PageTitle> Requests </PageTitle>
      <br />

      <div style={{ display: "flex", gap: "10px" }}>
        {currentView === "users" && (
          <>
            <Button onClick={() => setCurrentView("users")}>Users</Button>
            <Button
              bgColor={mainTheme.colors.grey}
              onClick={() => setCurrentView("owners")}
            >
              Owners
            </Button>
          </>
        )}
        {currentView === "owners" && (
          <>
            <Button
              bgColor={mainTheme.colors.grey}
              onClick={() => setCurrentView("users")}
            >
              Users
            </Button>
            <Button onClick={() => setCurrentView("owners")}>Owners</Button>
          </>
        )}
      </div>
      <br />
      <br />
      {currentView === "users" ? <RequestsTable /> : <RequestsTableOwners />}
    </div>
  );
};

export default Requests;
