import React from "react";
import Modal from "../../components/atoms/Modal";

const Login = () => {
  return (
    <div>
      <h1>Login</h1>
      <Modal />
    </div>
  );
};

export default Login;
