import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "../../atoms/Button";
import { registerFormElements } from "../formElementsData";
import FormElement from "../../atoms/FormElement";
import Logo from "../../atoms/Logo";
import { StyledForm } from "../styled";
import { registerFormValidationSchema } from "../../../utils/validationSchema";
import { auth } from "../../../firebase/firebaseConfig";
import {
  usersCollection,
  parkingsCollection,
} from "../../../firebase/firestoreUtils";
import { StyledFormControl } from "./StyledRegisterForm";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { closeModal } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GoStop } from "react-icons/go";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const RegisterForm = () => {
  const [parkings, setParkings] = useState([]);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    parkingsCollection.get().then((response) =>
      setParkings(
        response.docs
          .map((el) => {
            return { ...el.data(), id: el.id };
          })
          .filter((el) => el.status === "active")
          .sort((a, b) =>
            `${a.parkingName} - ${a.companyName}`.localeCompare(
              `${b.parkingName} - ${b.companyName}`
            )
          )
      )
    );
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
          firstName: "",
          surname: "",
          contactNumber: "",
          carPark: "",
          vehicleRegistration: "",
          acceptTerms: false,
          selectedParkingSpace: "",
        }}
        validationSchema={registerFormValidationSchema}
        // _________________Submit action: __________________
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          auth
            .createUserWithEmailAndPassword(values.email, values.password)
            .then((res) => {
              dispatch(closeModal());
              usersCollection
                .doc(res.user.uid)
                .set({
                  ...values,
                  role: "user",
                  userId: res.user.uid,
                  status: "pending",
                })
                .then(() => {
                  resetForm();
                  setIsModalOpen(true);

                  // setTimeout(() => {
                  //   setIsModalOpen(false);
                  //   dispatch(closeModal());
                  //   navigate("/welcome");
                  // }, 3000);
                });
            })

            .catch((err) => {
              console.log(err);
              // alert("Email already exists. Please choose a different email");
              resetForm();
              setIsModalOpen(true);
              setTimeout(() => {
                setIsModalOpen(false);
                dispatch(closeModal());
                navigate("/");
              }, 7000);
            });
          //resetForm();
          console.log("test");
        }}
      >
        {(props) => (
          <StyledForm>
            <Logo size="medium" />
            {registerFormElements.map((el, index) => (
              <>
                {index !== registerFormElements.length - 1 ? (
                  <FormElement
                    key={index}
                    inputName={el.inputName}
                    inputType={el.inputType}
                    labelContent={el.labelContent}
                  />
                ) : (
                  <>
                    <br />
                    <StyledFormControl>
                      <InputLabel id="demo-simple-select-label">
                        Parking
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Start time"
                        required
                        name="selectedParkingSpace"
                        value={props.values.selectedParkingSpace}
                        onChange={props.handleChange}
                      >
                        {parkings.map((el) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.parkingName} - {el.companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>

                    <FormElement
                      inputName={el.inputName}
                      inputType={el.inputType}
                      labelContent={el.labelContent}
                    />
                  </>
                )}
              </>
            ))}

            <Button type="submit">Submit</Button>
          </StyledForm>
        )}
      </Formik>
      {/* __________________alert email already exist: ___________________ */}
      <Dialog
        open={isModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ color: "red", textAlign: "center", fontSize: "15px" }}
          id="alert-dialog-title"
        >
          <GoStop style={{ margin: "0 5px 0 0" }} size={30} />
          {"   "}
          UNSUCCESSFUL REGISTRATION !
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px", textAlign: "center" }}
            id="alert-dialog-description"
          >
            This email address already exists
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterForm;
