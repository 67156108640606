import styled from "styled-components";

export const StyledPicture = styled.img`
  width: 600px;
  height: 400px;
  margin-top: 100px;
  @media (max-width: 831px) {
    width: 450px;
    height: 300px;
  }
  @media (max-width: 627px) {
    width: 300px;
    height: 200px;
  }
  @media (max-width: 491px) {
    width: 250px;
    height: 180px;
  }
`;

export const StyledIcon = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 100px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 30px 0;
`;
