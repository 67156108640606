import { createGlobalStyle } from "styled-components";
import UKNumberPlate from "../assets/fonts/UKNumberPlate.ttf";

const GlobalStyles = createGlobalStyle`

@font-face {
    font-family: UKNumberPlate;
    src: url(${UKNumberPlate});
    font-style: normal;
    font-weight: 400;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    margin: auto;
    /* font-family: 'Montserrat', sans-serif; */
font-family: 'Open Sans', sans-serif;
/* font-family: 'Roboto', sans-serif; */




  .MuiMenu-paper,
  .MuiPaper-elevation8,
  .MuiPopover-paper,
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
   {
    height: 350px !important;
  }

}

`;

export default GlobalStyles;
