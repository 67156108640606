import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { loggedRoutes } from "../utils/routes";
import Bookings from "../views/Bookings";
import CarPark from "../views/CarPark";
import RegisterCarParkOwner from "../views/RegisterCarParkOwner";
import Requests from "../views/Requests";
import Users from "../views/Users";
import UserGroups from "../views/UserGroups";
import Account from "../views/Account";
import Terms from "../views/Terms";
import Report from "../views/Report";
import Support from "../views/Support";
import Contact from "../views/Contact";
import Pricing from "../views/Pricing";
import { useSelector } from "react-redux";
import Welcome from "../views/Welcome";

const LoggedRouter = () => {
  const { currentUser } = useSelector((state) => state);
  return (
    <Routes>
      {/* user routes */}
      <Route path={loggedRoutes.bookings} element={<Bookings />} />
      <Route path={loggedRoutes.account} element={<Account />} />
      <Route path={loggedRoutes.report} element={<Report />} />
      <Route path={loggedRoutes.terms} element={<Terms />} />
      <Route path={loggedRoutes.support} element={<Support />} />
      <Route path={loggedRoutes.contact} element={<Contact />} />
      <Route path={loggedRoutes.pricing} element={<Pricing />} />`{" "}
      {currentUser?.role === "user" && (
        <>
          <Route
            path={loggedRoutes.registerCarParkOwner}
            element={<RegisterCarParkOwner />}
          />
          <Route path={loggedRoutes.welcome} element={<Welcome />} />
        </>
      )}
      ` `{" "}
      {currentUser?.role === "admin" && (
        <>
          <Route path={loggedRoutes.carPark} element={<CarPark />} />

          <Route path={loggedRoutes.users} element={<Users />} />
        </>
      )}
      ` `{" "}
      {currentUser?.role === "owner" && (
        <>
          <Route path={loggedRoutes.carPark} element={<CarPark />} />
          <Route path={loggedRoutes.users} element={<Users />} />
        </>
      )}
      `
      {currentUser?.role === "master" && (
        <>
          <Route path={loggedRoutes.carPark} element={<CarPark />} />
          <Route path={loggedRoutes.users} element={<Users />} />
          <Route path={loggedRoutes.requests} element={<Requests />} />
          <Route path={loggedRoutes.userGroups} element={<UserGroups />} />
        </>
      )}
      <Route path="*" element={<Navigate to={loggedRoutes.bookings} />} />
    </Routes>
  );
};

export default LoggedRouter;
