import React from "react";
import ReportForm from "../../components/forms/ReportForm";
import PageTitle from "../../components/atoms/PageTitle";

const Report = () => {
  return (
    <div>
      <PageTitle> Report </PageTitle>
      <ReportForm />
    </div>
  );
};

export default Report;
