import React from "react";
import PageTitle from "../../components/atoms/PageTitle";

const Terms = () => {
  return (
    <div>
      <PageTitle> Terms </PageTitle>
      <h3>Read our Terms and Conditions:</h3>
      <h5>1. ......</h5>
      <h5>2. ......</h5>
    </div>
  );
};

export default Terms;