import React, { useState } from "react";
import { Formik } from "formik";
import Button from "../../atoms/Button";
import { StyledForm } from "../styled";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import UKFlag from "../../../assets/images/united-kingdom.png";
import {
  StyledRegBox,
  RegisterNumberInput,
  RegBoxWrapper,
  RegBoxFlag,
  RegBoxLabel,
} from "../BookingsForm/StyledBookingForm";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  parkingsCollection,
  bookingsCollection,
} from "../../../firebase/firestoreUtils";
import { useEffect } from "react";
import styled from "styled-components";
import { FormElementWrapper } from "../../atoms/FormElement/StyledFormElement";
import { bookingValidationSchema } from "../../../utils/validationSchema";
import { MdCameraAlt } from "react-icons/md";
import { openModal } from "../../../redux/actions";
import { mainTheme } from "../../../globalStyles/themes/mainTheme";
import { DateAndTimeWrapper, StyledFormControl } from "./StyledBooking2";

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: 500;
`;

export const FullWidthSelect = styled(Select)`
  width: 350px;

  @media (max-width: 500px) {
    width: 50vw;
    align-items: center;
  }
`;

const generateHours = (minHour, minMinute) => {
  const startHour = minMinute <= 45 ? minHour : minHour + 1;

  const hours = [];

  for (let i = startHour; i <= 23; i++) {
    if (i > minHour || (i === minHour && minMinute === 0)) {
      hours.push(`${i}:00`);
    }

    [15, 30, 45].forEach((time) => {
      if (i > minHour || (i === minHour && time >= minMinute)) {
        hours.push(`${i}:${time}`);
      }
    });
  }

  return hours;
};

const BookingsForm2 = () => {
  const { currentUser } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTimesArray, setStartTimesArray] = useState([]);
  const [endTimesArray, setEndTimesArray] = useState([]);
  const [showPlaces, setShowPlaces] = useState(false);
  const [places, setPlaces] = useState([]);

  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  useEffect(() => {
    const effectDateFrom = new Date(
      dateFrom.getFullYear(),
      dateFrom.getMonth(),
      dateFrom.getDate()
    );

    const effectDateTo = new Date(
      dateTo.getFullYear(),
      dateTo.getMonth(),
      dateTo.getDate()
    );

    let finalStartHours = [];

    if (effectDateFrom.getTime() === today.getTime()) {
      const minHour = new Date().getHours();
      const minMinute = new Date().getMinutes();

      finalStartHours = generateHours(minHour, minMinute);

      setStartTimesArray(finalStartHours);
    } else {
      finalStartHours = generateHours(0, 0);

      setStartTimesArray(finalStartHours);
    }

    let finalEndHours = [];

    if (effectDateTo.getTime() > effectDateFrom.getTime()) {
      finalEndHours = generateHours(0, 0);
      setEndTimesArray(finalEndHours);
    } else if (startTime !== null) {
      const startTimeParts = startTime.split(":").map((el) => parseInt(el));
      if (startTimeParts[1] + 15 === 60) {
        finalEndHours = generateHours(startTimeParts[0] + 1, 0);
        setEndTimesArray(finalEndHours);
      } else {
        finalEndHours = generateHours(
          startTimeParts[0],
          startTimeParts[1] + 15
        );
        setEndTimesArray(finalEndHours);
      }
    }

    if (finalStartHours.indexOf(startTime) === -1) {
      setStartTime("");
    }

    if (finalEndHours.indexOf(endTime) === -1) {
      setEndTime("");
    }

    setShowPlaces(startTime && endTime);

    if (startTime && endTime) {
      parkingsCollection.get().then((response) => {
        const numberOfSpaces = parseInt(
          response.docs
            .find((el) => el.id === currentUser.selectedParkingSpace)
            .data().parkingSpaces
        );

        bookingsCollection.get().then((response) => {
          const existBookings = response.docs
            .filter(
              (el) => el.data().parkingId === currentUser.selectedParkingSpace
            )
            .map((el) => el.data());

          const availableSpaces = [];

          for (let i = 1; i <= numberOfSpaces; i++) {
            const selectedStart = new Date(
              `${dateFrom.getFullYear()}-${
                dateFrom.getMonth() + 1
              }-${dateFrom.getDate()} ${startTime}`
            );

            const selectedEnd = new Date(
              `${dateTo.getFullYear()}-${
                dateTo.getMonth() + 1
              }-${dateTo.getDate()} ${endTime}`
            );

            const isInExistBooking = existBookings.filter((el) => {
              const bookingStart = new Date(
                `${el.effectiveFrom} ${el.timeFrom}`
              ).getTime();
              const bookingEnd = new Date(
                `${el.effectiveUntil} ${el.timeTo}`
              ).getTime();

              return (
                (selectedStart.getTime() >= bookingStart &&
                  selectedStart.getTime() <= bookingEnd) ||
                (selectedEnd.getTime() >= bookingStart &&
                  selectedEnd.getTime() <= bookingEnd)
              );
            });

            const isInSelectedBooking = existBookings.filter((el) => {
              const bookingStart = new Date(
                `${el.effectiveFrom} ${el.timeFrom}`
              ).getTime();
              const bookingEnd = new Date(
                `${el.effectiveUntil} ${el.timeTo}`
              ).getTime();

              return (
                (bookingStart >= selectedStart.getTime() &&
                  bookingStart <= selectedEnd.getTime()) ||
                (bookingEnd >= selectedStart.getTime() &&
                  bookingEnd <= selectedEnd.getTime())
              );
            });

            if (
              !isInExistBooking.find((el) => el.spaceId === i) &&
              !isInSelectedBooking.find((el) => el.spaceId === i)
            ) {
              availableSpaces.push(i);
            }
          }

          setPlaces(availableSpaces);
        });
      });
    } else {
      setPlaces([]);
    }
    //when we add this comment then you tell to stop checking next line and elements in dependency array:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, startTime, endTime]);

  useEffect(() => {
    if (dateFrom > dateTo) {
      setDateTo(dateFrom);
    }
  }, [dateFrom, dateTo]);

  return (
    <Formik
      initialValues={{
        vehicleRegistration: "",
        spaceNo: "",
      }}
      valiadtionSchema={bookingValidationSchema}
      onSubmit={(values, { resetForm }) => {
        if (!values.spaceNo) return;
        const data = {
          effectiveFrom: `${dateFrom.getFullYear()}-${
            dateFrom.getMonth() + 1 > 9
              ? dateFrom.getMonth() + 1
              : "0" + (dateFrom.getMonth() + 1)
          }-${dateFrom.getDate()}`,
          effectiveUntil: `${dateTo.getFullYear()}-${
            dateTo.getMonth() + 1 > 9
              ? dateTo.getMonth() + 1
              : "0" + (dateTo.getMonth() + 1)
          }-${dateTo.getDate()}`,
          parkingId: currentUser.selectedParkingSpace,
          spaceId: values.spaceNo,
          timeFrom: startTime,
          timeTo: endTime,
          userId: currentUser.userId,
          vehicleRegistration: currentUser.vehicleRegistration,
        };

        bookingsCollection
          .doc()
          .set({
            ...data,
          })
          .then((response) => {
            resetForm();
            setDateFrom(new Date());
            setDateTo(new Date());
            setStartTime("");
            setEndTime("");
            setPlaces([]);
          })
          .then(() => window.location.reload());
      }}
    >
      {({ values, handleChange }) => (
        <StyledForm>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DateAndTimeWrapper>
                <DesktopDatePicker
                  label="Date From"
                  inputFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  value={dateFrom}
                  onChange={(date) => setDateFrom(new Date(date))}
                  renderInput={(params) => <TextField {...params} />}
                  name="date_from"
                />
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Start Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Start time"
                    value={startTime}
                    name="hour_from"
                    onChange={(e) => setStartTime(e.target.value)}
                  >
                    {startTimesArray.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DateAndTimeWrapper>
              <DateAndTimeWrapper>
                <DesktopDatePicker
                  label="Date To"
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                  value={dateTo}
                  minDate={dateFrom}
                  onChange={(date) => setDateTo(new Date(date))}
                />
                {/* 235px */}
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel id="demo-simple-select-label">
                    End Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="End time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  >
                    {endTimesArray.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DateAndTimeWrapper>
            </Stack>
          </LocalizationProvider>
          <StyledRegBox>
            <RegBoxLabel htmlFor="vehicleRegistration">
              Vehicle Registration
            </RegBoxLabel>
            <RegBoxWrapper>
              <RegBoxFlag>
                <img src={UKFlag} alt="Registration Number" />
                UK
              </RegBoxFlag>
              <RegisterNumberInput
                type="text"
                name="vehicleRegistration"
                value={currentUser?.vehicleRegistration}
                disabled
              />
            </RegBoxWrapper>
          </StyledRegBox>
          <Button
            bgColor={mainTheme.colors.darkBlue}
            style={{
              width: "220px",
            }}
            onClick={() => dispatch(openModal("showPicture"))}
          >
            {" "}
            <MdCameraAlt size={20} />
            {"   "}
            Car Park
          </Button>

          {showPlaces && (
            <FormElementWrapper>
              <StyledFormControl>
                <Label style={{ color: "grey", marginLeft: "20px" }}>
                  space no.
                </Label>
                <FullWidthSelect
                  name="spaceNo"
                  label="Car park space"
                  required
                  onChange={handleChange}
                  value={values.spaceNo}
                >
                  {places.map((place, idx) => (
                    <MenuItem key={idx} value={place}>
                      {place}
                    </MenuItem>
                  ))}
                </FullWidthSelect>
              </StyledFormControl>
            </FormElementWrapper>
          )}

          <br />
          <Button
            type="submit"
            // onClick={() => dispatch(openModal("parkingMap"))}
          >
            Book
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default BookingsForm2;
