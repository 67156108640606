import { loggedRoutes } from "../../../utils/routes";

import { RiUserFollowFill, RiParkingFill } from "react-icons/ri";
import { ImUsers } from "react-icons/im";
import { FaUsers } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";

import { BsFillCameraFill } from "react-icons/bs";

export const masterSidebarData = [
  {
    path: loggedRoutes.bookings,
    icon: <IoIosListBox />,
    routeName: "Bookings",
  },
  {
    path: loggedRoutes.account,
    icon: <MdAccountCircle />,
    routeName: "Account",
  },

  {
    path: loggedRoutes.report,
    icon: <BsFillCameraFill />,
    routeName: "Report",
  },

  {
    path: loggedRoutes.carPark,
    icon: <RiParkingFill />,
    routeName: "Car Parks",
  },
  {
    path: loggedRoutes.users,
    icon: <ImUsers />,
    routeName: "Users",
  },
  // {
  //   path: loggedRoutes.userGroups,
  //   icon: <FaUsers />,
  //   routeName: "User Groups",
  // },
  {
    path: loggedRoutes.requests,
    icon: <RiUserFollowFill />,
    routeName: "Requests",
  },
];

export const ownerSidebarData = [
  {
    path: loggedRoutes.bookings,
    icon: <IoIosListBox />,
    routeName: "Bookings",
  },
  {
    path: loggedRoutes.account,
    icon: <MdAccountCircle />,
    routeName: "Account",
  },

  {
    path: loggedRoutes.report,
    icon: <BsFillCameraFill />,
    routeName: "Report",
  },

  {
    path: loggedRoutes.carPark,
    icon: <RiParkingFill />,
    routeName: "Car Parks",
  },
  {
    path: loggedRoutes.users,
    icon: <ImUsers />,
    routeName: "Users",
  },
];

export const userSidebarData = [
  {
    path: loggedRoutes.bookings,
    icon: <IoIosListBox />,
    routeName: "Bookings",
  },
  {
    path: loggedRoutes.account,
    icon: <MdAccountCircle />,
    routeName: "Account",
  },

  {
    path: loggedRoutes.report,
    icon: <BsFillCameraFill />,
    routeName: "Report",
  },
];

export const otherParkingData = [
  {
    path: loggedRoutes.account,
    icon: <MdAccountCircle />,
    routeName: "Account",
  },

  {
    path: loggedRoutes.registerCarParkOwner,
    icon: <FaUsers />,
    routeName: "Register as Car Park Owner",
  },
];

export const adminSidebarData = [
  {
    path: loggedRoutes.bookings,
    icon: <IoIosListBox />,
    routeName: "Bookings",
  },
  {
    path: loggedRoutes.account,
    icon: <MdAccountCircle />,
    routeName: "Account",
  },

  {
    path: loggedRoutes.report,
    icon: <BsFillCameraFill />,
    routeName: "Report",
  },

  {
    path: loggedRoutes.carPark,
    icon: <RiParkingFill />,
    routeName: "Car Parks",
  },
  {
    path: loggedRoutes.users,
    icon: <ImUsers />,
    routeName: "Users",
  },
];
