import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "../../atoms/Button";
import { reportFormElements } from "../formElementsData";
import FormElement from "../../atoms/FormElement";
import { StyledForm } from "../styled";
import { sendReportFormValidationSchema } from "../../../utils/validationSchema";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { styled as materialStyled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { MenuItem } from "@mui/material";
import { storage } from "../../../firebase/firebaseConfig";
import {
  reportsCollection,
  parkingsCollection,
} from "../../../firebase/firestoreUtils";
import { FormElementWrapper } from "../../atoms/FormElement/StyledFormElement";
import { DateWrapper } from "./StyledReportForm";
import { useSelector } from "react-redux";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { TextArea, Label, FullWidthSelect } from "./StyledReportForm";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import emailjs from "@emailjs/browser";

const Input = materialStyled("input")({
  display: "none",
});

const ReportForm = () => {
  const { currentUser } = useSelector((state) => state);
  const [url, setUrl] = useState("");
  const [parkings, setParkings] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());

  useEffect(() => {
    parkingsCollection.get().then((response) => {
      setParkings(
        response.docs
          .map((el) => ({ id: el.id, ...el.data() }))
          .filter((parking) => parking.parkingName !== "Other")
      );
    });
  }, []);
  // const getReportUser = (report) => {
  //   return usersList.find((user) => {
  //     return user.userId === report.userId;
  //   });
  // };
  // const getMaxDateTo = () => {
  //   const newDate = new Date(dateFrom);

  //   newDate.setDate(newDate.getDate() + 30);

  //   return newDate;
  // };

  const upload = (image) => {
    if (image === null) return;
    storage
      .ref(`/images/${image?.name}`)
      .put(image)
      .on(
        "state_changed",
        () => console.log("success"),
        (res) => {}
      );

    storage
      .ref("images")
      .child(image.name)
      .getDownloadURL()
      .then((url) => {
        console.log(url, "URL");
        setUrl(url);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          carPark: "",
          vehicleRegistration: "",
          description: "",
          reportType: "",
          firstName: currentUser?.firstName,
          surname: currentUser?.surname,
        }}
        validationSchema={sendReportFormValidationSchema}
        onSubmit={(values, { resetForm }) => {
          const data = {
            ...values,
            userId: currentUser?.userId,
            firstName: currentUser?.firstName,
            surname: currentUser?.surname,
            reportImage: url,
            data: dateFrom,
          };
          reportsCollection.add(data).then((response) => {
            console.log(response);
          });
          resetForm();
          setUrl("");
          emailjs
            .send(
              "service_0xl52xh",
              "template_irr3u9k",
              data,
              "e_sJhRBCMgzKkKLi3"
            )
            .then((res) => {
              resetForm();
            })
            .catch((err) => {});
        }}
      >
        {({ values, handleChange }) => (
          <StyledForm>
            {reportFormElements.map((el) => (
              <FormElement
                key={el.inputName}
                inputName={el.inputName}
                inputType={el.inputType}
                labelContent={el.labelContent}
              />
            ))}
            <FormElementWrapper>
              <Label>Car park</Label>

              <FullWidthSelect
                name="carPark"
                label="Car park"
                required
                onChange={handleChange}
                value={values.carPark}
              >
                {parkings.map((parking) => (
                  <MenuItem
                    key={parking.id}
                    value={parking.parkingName + " - " + parking.companyName}
                  >
                    {parking.parkingName + " - " + parking.companyName}
                  </MenuItem>
                ))}
              </FullWidthSelect>
              <br />
              <Label>Report type</Label>

              <FullWidthSelect
                name="reportType"
                label="Report Type"
                required
                onChange={handleChange}
                value={values.reportType}
              >
                <MenuItem value={"blockedSpaceReport"}>Blocked Space</MenuItem>
                <MenuItem value={"lateReturnReport"}>Late Return</MenuItem>
                <MenuItem value={"noBookingReport"}>No Booking</MenuItem>
                <MenuItem value={"wrongParkingReport"}>
                  Wrong Parking Space
                </MenuItem>
              </FullWidthSelect>
              <br />

              <Label>Description</Label>
              <TextArea
                name="description"
                value={values.description}
                onChange={handleChange}
              ></TextArea>
              <br />
              <DateWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="dd/MM/yyyy"
                    value={dateFrom}
                    onChange={(value) => setDateFrom(value)}
                    renderInput={(
                      params //_____<____render pass params from parent________
                    ) => <TextField {...params} style={{ width: "100%" }} />}
                  />
                </LocalizationProvider>
              </DateWrapper>
            </FormElementWrapper>
            <br />
            <Label
              style={{
                textJustify: "center",
              }}
            >
              Upload Image
            </Label>
            <label htmlFor="icon-button-file" style={{ marginBottom: "20px" }}>
              <form onSubmit={upload}>
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={(e) => upload(e.target.files[0])}
                />

                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <UploadFileIcon />
                </IconButton>
              </form>
            </label>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={url}
                alt=""
                style={{
                  width: "75%",
                  borderRadius: "15px",
                  marginBottom: "5px",
                  textJustify: "center",
                }}
              />
            </div>

            <Button type="submit">Submit</Button>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default ReportForm;
