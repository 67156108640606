import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "../Button";
import FormElement from "../FormElement";

import { StyledForm } from "../../forms/styled";

import { editUsersFormElements } from "../../forms/formElementsData";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { StyledFormControl } from "./StyledEditIcon";

import {
  usersCollection,
  parkingsCollection,
} from "../../../firebase/firestoreUtils";

const EditUsersIcon = ({ user }) => {
  const [parkings, setParkings] = useState([]);

  useEffect(() => {
    parkingsCollection.get().then((response) =>
      setParkings(
        response.docs.map((el) => {
          return { ...el.data(), id: el.id };
        })
      )
    );
  }, []);

  console.log(user);
  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        surname: user.surname,
        vehicleRegistration: user.vehicleRegistration,
        contactNumber: user.contactNumber,
        selectedParkingSpace: "",
        role: "",
      }}
      onSubmit={(values, { resetForm }) => {
        console.log(values.selectedParkingSpace, "TUTAJ");
        usersCollection
          .doc(user.userId)
          .update({ ...values })
          .then((response) => {
            window.location.reload();
          });

        if (values.role === "admin") {
          parkingsCollection
            .doc(values.selectedParkingSpace)
            .update({ adminId: user.userId })
            .then((response) => {
              window.location.reload();
            });
        } else if (values.role === "owner") {
          parkingsCollection
            .doc(values.selectedParkingSpace)
            .update({ userId: user.userId })
            .then((response) => {
              window.location.reload();
            });
        }
      }}
    >
      {(props) => (
        <StyledForm>
          {editUsersFormElements.map((el) => (
            <FormElement
              inputName={el.inputName}
              inputType={el.inputType}
              labelContent={el.labelContent}
            />
          ))}
          <br />
          <StyledFormControl style={{ minWidth: "350px" }}>
            <InputLabel id="demo-simple-select-label">Parking</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Start time"
              required
              name="selectedParkingSpace"
              value={props.values.selectedParkingSpace}
              onChange={props.handleChange}
            >
              {parkings.map((el) => (
                <MenuItem value={el.id}>
                  {el.parkingName} - {el.companyName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <br />
          <br />
          <StyledFormControl>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Start time"
              required
              name="role"
              value={props.values.role}
              onChange={props.handleChange}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="owner">Owner</MenuItem>
            </Select>
          </StyledFormControl>
          <br />
          <Button type="submit">Save</Button>
        </StyledForm>
      )}
    </Formik>
  );
};

export default EditUsersIcon;
