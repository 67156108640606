import Button from "../../atoms/Button";
import { registerCarParkFormElements } from "../formElementsData";
import FormElement from "../../atoms/FormElement";
import Logo from "../../atoms/Logo";
import { StyledForm } from "../styled";

const RegisterCarParkForm = ({ setNext }) => {
  return (
    <>
      <StyledForm>
        <Logo size="medium" />
        {registerCarParkFormElements.map((el, index) => (
          <>
            {index !== registerCarParkFormElements.length - 1 ? (
              <FormElement
                inputName={el.inputName}
                inputType={el.inputType}
                labelContent={el.labelContent}
              />
            ) : (
              <>
                <FormElement
                  inputName={el.inputName}
                  inputType={el.inputType}
                  labelContent={el.labelContent}
                />
              </>
            )}
          </>
        ))}
        <Button type="button" onClick={() => setNext(true)}>
          Next
        </Button>
      </StyledForm>
    </>
  );
};

export default RegisterCarParkForm;
