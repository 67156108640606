import React from "react";
import AccountForm from "../../components/forms/AccountForm";
import PageTitle from "../../components/atoms/PageTitle";

const Account = () => {
  return (
    <div>
      <PageTitle> Account </PageTitle>
      <AccountForm />
    </div>
  );
};

export default Account;
