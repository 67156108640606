import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/actions";
import { usersCollection } from "../../firebase/firestoreUtils";
import { parkingsCollection } from "../../firebase/firestoreUtils";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

// const rows = [...usersData];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "vehicleRegistration",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Reg",
  },

  {
    id: "carParkAccess",
    numeric: false,
    disablePadding: false,
    label: "Car Park Access",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "contactNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone No.",
  },
  // {
  //   id: "effectiveFrom",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Effective From",
  // },
  // {
  //   id: "timeFrom",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Time From",
  // },
  // {
  //   id: "effectiveUntil",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Effective Until",
  // },
  // {
  //   id: "timeTo",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Time To",
  // },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "Edit",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const UsersTable = () => {
  //state: global variable where value of state changes when state is changed or updated then component is rendering
  const { currentUser } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usersList, setUsersList] = React.useState([]); //stan users
  const [parkings, setParkings] = React.useState([]);

  useEffect(() => {
    // get data from firebase:
    //useEffect has two paramets, first function and second in that case table- it is used each time when state will change
    //useEffect will open always when we open component
    usersCollection.get().then(
      (response) => {
        let users = response.docs.map((el) => el.data());

        if (currentUser.role === "admin") {
          users = users.filter(
            (el) => el.selectedParkingSpace === currentUser.selectedParkingSpace
          );
        }

        setUsersList(users);
      },
      [parkings.length]
    );

    parkingsCollection.get().then((response) => {
      setParkings(
        response.docs.map((el) => {
          return { ...el.data(), id: el.id };
        })
      );
    });
  }, [currentUser.role, currentUser.selectedParkingSpace, parkings.length]);

  const getParking = (id) => {
    return parkings.find((parking) => parking.id === id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={usersList.length}
            />
            <TableBody>
              {stableSort(usersList, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell padding="checkbox"></TableCell>

                      <TableCell align="left">
                        {row.firstName} {row.surname}
                      </TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">
                        {row.vehicleRegistration}
                      </TableCell>

                      <TableCell align="left">
                        {getParking(row.selectedParkingSpace)?.parkingName}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.contactNumber}</TableCell>

                      <TableCell align="left">
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => dispatch(openModal("edit", row))}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() =>
                              dispatch(
                                openModal("delete", "user", "users", row.userId)
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={usersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default UsersTable;
