import { loggedAndUnloggedRoutes } from "../../../utils/routes";

export const footerData = [
  {
    path: loggedAndUnloggedRoutes.terms,
    routeName: "Terms & Conditions",
  },
  // {
  //   path: loggedAndUnloggedRoutes.support,
  //   routeName: "Support",
  // },

  {
    path: loggedAndUnloggedRoutes.contact,
    routeName: "|        Contact",
  },
  // {
  //   path: loggedAndUnloggedRoutes.pricing,
  //   routeName: "Pricing",
  // },
];
