import styled from "styled-components";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { FormControl } from "@mui/material";

export const DateAndTimeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  @media (max-width: 800px) {
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 30vw !important;
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  min-width: 350px !important;

  /* @media (max-width: 900px) {
    min-width: 70vw !important;
  }
  @media (max-width: 600px) {
    min-width: 50vw !important;
  } */
`;
