export const parkingData = {
  ealing: {
    id: 1,
    name: "Ealing",
    parkingSpaces: [
      {
        spaceNumber: 1,
        bookingData: {
          fromDate: "16/12/2021",
          toDate: "17/12/2021",
          startTime: "23:00",
          endTime: "15:00",
          vehicleRegistriation: "MV11 BYH",
        },
      },
      {
        spaceNumber: 2,
        bookingData: {
          fromDate: "18/12/2021",
          toDate: "19/12/2021",
          startTime: "12:00",
          endTime: "15:00",
          vehicleRegistriation: "HV17 ABC",
        },
      },
      {
        spaceNumber: 3,
        bookingData: {
          fromDate: "26/12/2021",
          toDate: "30/12/2021",
          startTime: "13:00",
          endTime: "12:00",
          vehicleRegistriation: "JV18 YXY",
        },
      },
      {
        spaceNumber: 4,
        bookingData: null,
      },
      {
        spaceNumber: 5,
        bookingData: null,
      },
      {
        spaceNumber: 6,
        bookingData: null,
      },
      {
        spaceNumber: 7,
        bookingData: {
          fromDate: "29/12/2021",
          toDate: "30/12/2021",
          startTime: "12:15",
          endTime: "14:00",
          vehicleRegistriation: "KV15 YXY",
        },
      },
      {
        spaceNumber: 8,
        bookingData: null,
      },
      {
        spaceNumber: 9,
        bookingData: null,
      },
      {
        spaceNumber: 10,
        bookingData: null,
      },
      {
        spaceNumber: 11,
        bookingData: null,
      },
      {
        spaceNumber: 12,
        bookingData: null,
      },
      {
        spaceNumber: 13,
        bookingData: null,
      },
      {
        spaceNumber: 14,
        bookingData: {
          fromDate: "19/12/2021",
          toDate: "19/12/2021",
          startTime: "08:15",
          endTime: "17:00",
          vehicleRegistriation: "SV15 YXY",
        },
      },

      {
        spaceNumber: 15,
        bookingData: null,
      },
      {
        spaceNumber: 16,
        bookingData: null,
      },
      {
        spaceNumber: 17,
        bookingData: null,
      },
      {
        spaceNumber: 18,
        bookingData: null,
      },
      {
        spaceNumber: 19,
        bookingData: null,
      },
      {
        spaceNumber: 20,
        bookingData: null,
      },
    ],
  },
};
