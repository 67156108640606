import styled from "styled-components";
import { FormControl } from "@mui/material";

export const ResetButton = styled.button`
  background: #fff;
  border: none;
  margin-bottom: 20px;
  color: rgb(42, 82, 207);
  cursor: pointer;
`;

export const StyledFormControl = styled(FormControl)`
  min-width: 350px !important;

  @media (max-width: 900px) {
    min-width: 70vw !important;
  }
`;
