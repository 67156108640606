import styled, { css } from "styled-components";

export const StyledLogo = styled.img`
  ${({ size }) =>
    size === "small"
      ? css`
          width: 110px;
          height: 45px;
        `
      : size === "medium"
      ? css`
          width: 240px;
          height: 70px;
        `
      : size === "large"
      ? css`
          width: 320px;
          height: 95px;
        `
      : css`
          width: 150px;
          height: 60px;
        `}
`;
