import React from "react";
import picture from "../../../assets/images/CarparkJohnsonsLondon.JPG";
import { StyledPicture, StyledIcon } from "./StyledPicture";
import { RiProductHuntFill } from "react-icons/ri";

const ShowPictureButton = ({ size }) => {
  return (
    <>
      <StyledIcon>
        <RiProductHuntFill />
      </StyledIcon>
      <StyledPicture src={picture} alt="picture" size={size} />
    </>
  );
};

export default ShowPictureButton;
