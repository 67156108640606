import React from "react";
import UserGroupsTable from "../../components/tables/UserGroupsTable";
import PageTitle from "../../components/atoms/PageTitle";
import { useSelector } from "react-redux";
import AddButton from "../../components/atoms/AddButton";

const UserGroups = () => {
  const { currentUser } = useSelector((state) => state);

  return (
    <div>
      <PageTitle> User Groups </PageTitle>
      {currentUser && currentUser.role === "master" && <AddButton />}
      <br />
      <UserGroupsTable />
    </div>
  );
};

export default UserGroups;
