import React from "react";
import Box from "@mui/material/Box";
import { autocompleteClasses, Modal as MaterialModal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../redux/actions";
import LoginForm from "../../forms/LoginForm";
import RegisterForm from "../../forms/RegisterForm";
import ShowPictureButton from "../../atoms/ShowPictureButton";
import EditUsersIcon from "../../atoms/EditUsersIcon";
import EditCarPkIcon from "../../atoms/EditCarPkIcon";
import ConfirmDelete from "../../atoms/ConfirmDelete";
import styled from "styled-components";
import ParkingMap from "../../ParkingMap";

import RegisterCarParkOwner from "../../../views/RegisterCarParkOwner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  height: "92vh",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media (max-width: 920px)": {
    width: "80vw",
  },
  "@media (max-width: 543px)": {
    width: "70vw",
  },
};

const showPictureModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  height: "750px",
  // height: "92vh",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media (max-width: 491px)": {
    width: "80vw",
    height: "200px",
  },
  "@media (max-width: 627px)": {
    width: "80vw",
    height: "400px",
  },
  "@media (max-width: 831px)": {
    width: "80vw",
    height: "650px",
  },
};
const deleteModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  height: "22vh",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const loginModalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  height: "50vh",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",

  "@media (max-width: 920px)": {
    width: "94vw",
  },
};
const StyledBox = styled(Box)`
  position: relative;
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    position: absolute;
    height: 70vh;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 70vh;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    height: 70vh;
  }
`;

const Modal = () => {
  const manageModal = useSelector((state) => state.manageModal);

  const dispatch = useDispatch();

  return (
    <MaterialModal
      open={manageModal.isModalOpen}
      onClose={() => dispatch(closeModal())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox
        sx={
          manageModal.modalType === "delete"
            ? deleteModalStyles
            : manageModal.modalType === "login"
            ? loginModalStyles
            : manageModal.modalType === "showPicture"
            ? showPictureModalStyles
            : style
        }
      >
        {manageModal.modalType === "login" ? (
          <LoginForm />
        ) : manageModal.modalType === "register" ? (
          <RegisterForm />
        ) : manageModal.modalType === "registerCarParkOwner" ? (
          <RegisterCarParkOwner />
        ) : manageModal.modalType === "registerCarPark" ? (
          <RegisterCarParkOwner />
        ) : manageModal.modalType === "showPicture" ? (
          <ShowPictureButton />
        ) : manageModal.modalType === "edit" ? (
          <EditUsersIcon user={manageModal.additonalInfo} />
        ) : manageModal.modalType === "editCarPkAdmin" ? (
          <EditCarPkIcon parking={manageModal.additonalInfo} />
        ) : manageModal.modalType === "delete" ? (
          <ConfirmDelete
            whatToDelete={manageModal.additonalInfo}
            collectionName={manageModal.collectionName}
            objectId={manageModal.objectId}
          />
        ) : (
          <ParkingMap />
        )}
      </StyledBox>
    </MaterialModal>
  );
};

export default Modal;
