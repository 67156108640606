import Button from "../../atoms/Button";
import { registerCarParkOwnerFormElements } from "../formElementsData";
import FormElement from "../../atoms/FormElement";
import Logo from "../../atoms/Logo";
import { StyledForm } from "../styled";
import { mainTheme } from "../../../globalStyles/themes/mainTheme";

const RegisterCarParkOwnerForm = ({ setNext }) => {
  return (
    <StyledForm>
      <Logo size="medium" />
      {registerCarParkOwnerFormElements.map((el, index) => (
        <>
          {index !== registerCarParkOwnerFormElements.length - 1 ? (
            <FormElement
              inputName={el.inputName}
              inputType={el.inputType}
              labelContent={el.labelContent}
            />
          ) : (
            <>
              <label
                htmlFor="icon-button-file"
                style={{ marginBottom: "20px" }}
              ></label>
              <FormElement
                inputName={el.inputName}
                inputType={el.inputType}
                labelContent={el.labelContent}
              />
            </>
          )}
        </>
      ))}
      <Button
        bgColor={mainTheme.colors.grey}
        type="button"
        onClick={() => setNext(false)}
      >
        Back
      </Button>
      <br />
      <Button type="submit">Save</Button>
    </StyledForm>
  );
};

export default RegisterCarParkOwnerForm;
