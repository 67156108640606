import React from "react";
import BookingsForm2 from "../../components/forms/BookingsForm2";
import BookingsTable from "../../components/tables/BookingsTable";
import PageTitle from "../../components/atoms/PageTitle";

const Bookings = () => {
  return (
    <div>
      <div>
        <PageTitle>Bookings</PageTitle>
        {/* <Logout/> */}
      </div>
      <BookingsForm2 />

      <BookingsTable />
    </div>
  );
};

export default Bookings;
