import React from "react";
import PageTitle from "../../components/atoms/PageTitle";

const Support = () => {
  return (
    <div>
      <PageTitle> Support </PageTitle>
      <h3>Support contact options like chat, phone or email for your Car Park App</h3>
      <br/>
      <h4>> chat</h4>
      <h4>> phone</h4>
      <h4>> email</h4>
    </div>
  );
};

export default Support;