const initialState = {
  manageModal: {
    modalType: "login",
    additonalInfo: "",
    isModalOpen: false,
    onDelete: "",
  },
  currentUser: null,
  selectedParkingSpace: null,
  parkingSpaces: [],
  //   parkingPlan: []
};

const rootReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN_MODAL":
      return {
        ...state,
        manageModal: {
          modalType: payload.modalType,
          additonalInfo: payload.additonalInfo,
          collectionName: payload.collectionName,
          objectId: payload.objectId,
          isModalOpen: true,
        },
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        manageModal: {
          ...state.manageModal,
          isModalOpen: false,
        },
      };

    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: payload,
      };

    case "SET_SELECTED_PARKING_SPACE":
      return {
        ...state,
        selectedParkingSpace: payload,
      };

    case "SET_PARKING_SPACES":
      return {
        ...state,
        parkingSpaces: payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
