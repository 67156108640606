import { Link } from "react-router-dom";

export const loginFormElements = [
  {
    inputName: "email",
    inputType: "email",
    labelContent: "Email",
  },
  {
    inputName: "password",
    inputType: "password",
    labelContent: "Password",
  },
];

export const registerFormElements = [
  {
    inputName: "firstName",
    inputType: "text",
    labelContent: "First Name",
  },
  {
    inputName: "surname",
    inputType: "text",
    labelContent: "Surname",
  },
  {
    inputName: "email",
    inputType: "email",
    labelContent: "Email",
  },
  {
    inputName: "password",
    inputType: "password",
    labelContent: "Password",
  },
  {
    inputName: "confirmPassword",
    inputType: "password",
    labelContent: "Confirm Password",
  },

  {
    inputName: "contactNumber",
    inputType: "number",
    labelContent: "Contact number",
  },

  {
    inputName: "vehicleRegistration",
    inputType: "text",
    labelContent: "Vehicle Registration",
  },

  {
    inputName: "acceptTerms",
    inputType: "checkbox",
    labelContent: <Link to="/">Acceptance of Terms of Service</Link>,
  },
];

export const accountFormElements = [
  {
    inputName: "firstName",
    inputType: "text",
    labelContent: "First Name",
    disabled: false,
  },
  {
    inputName: "surname",
    inputType: "text",
    labelContent: "Surname",
    disabled: false,
  },
  {
    inputName: "email",
    inputType: "email",
    labelContent: "Email",
    disabled: true,
  },
  {
    inputName: "userGroups",
    inputType: "text",
    labelContent: "Car Park Name",
    disabled: true,
  },
  {
    inputName: "roles",
    inputType: "text",
    labelContent: "Roles",
    disabled: true,
  },

  {
    inputName: "activeVehicleRegistration",
    inputType: "text",
    labelContent: "Active Vehicle Registration",
    disabled: false,
  },
];

export const reportFormElements = [
  {
    inputName: "vehicleRegistration",
    inputType: "text",
    labelContent: "Vehicle Registration",
  },
];

export const editUsersFormElements = [
  {
    inputName: "firstName",
    inputType: "text",
    labelContent: "First Name",
    disabled: false,
  },
  {
    inputName: "surname",
    inputType: "text",
    labelContent: "Surname",
    disabled: false,
  },
  {
    inputName: "vehicleRegistration",
    inputType: "text",
    labelContent: "Active Vehicle Registration",
  },
  {
    inputName: "contactNumber",
    inputType: "tel",
    labelContent: "Contact number",
  },
];

export const editParkingFormElements = [
  {
    inputName: "parkingName",
    inputType: "text",
    labelContent: "Car Park Name",
  },
  {
    inputName: "companyName",
    inputType: "text",
    labelContent: "Company Name",
  },
];

export const registerCarParkOwnerFormElements = [
  {
    inputName: "companyName",
    inputType: "text",
    labelContent: "Company Name",
  },
  {
    inputName: "contactNo",
    inputType: "tel",
    labelContent: "Contact Number",
  },
  {
    inputName: "email",
    inputType: "email",
    labelContent: "Email",
  },
  // {
  //   inputName: "acceptTerms",
  //   inputType: "checkbox",
  //   labelContent: <Link to="/">Acceptance of Terms of Service</Link>,
  // },
];

export const registerCarParkFormElements = [
  {
    inputName: "parkingName",
    inputType: "text",
    labelContent: "Car Park Name",
  },
  {
    inputName: "parkingSpaces",
    inputType: "text",
    labelContent: "Number of Scapces",
  },
  {
    inputName: "carParkAddress",
    inputType: "text",
    labelContent: "Car Park Address",
  },
  {
    inputName: "town",
    inputType: "text",
    labelContent: "Town",
  },
  {
    inputName: "county",
    inputType: "text",
    labelContent: "County",
  },
  {
    inputName: "postcode",
    inputType: "text",
    labelContent: "Post Code",
  },
];

export const contactUsFormElements = [
  {
    inputName: "firstName",
    inputType: "text",
    labelContent: "Firstname",
  },
  {
    inputName: "surname",
    inputType: "text",
    labelContent: "Surname",
  },
  {
    inputName: "email",
    inputType: "text",
    labelContent: "Email",
  },
];
