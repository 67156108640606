import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import {
  bookingsCollection,
  parkingsCollection,
} from "../../firebase/firestoreUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/actions";

// const rows = [...bookingsData];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "vehicleRegistration",
    numeric: false,
    disablePadding: false,
    label: "Vehicle Registration",
  },

  {
    id: "carParkName",
    numeric: false,
    disablePadding: false,
    label: "Car Park Name",
  },
  {
    id: "spaceNo",
    numeric: false,
    disablePadding: false,
    label: "Space No.",
  },

  {
    id: "effectiveFrom",
    numeric: false,
    disablePadding: false,
    label: "Effective From",
  },
  {
    id: "timeFrom",
    numeric: false,
    disablePadding: false,
    label: "Time From",
  },
  {
    id: "effectiveUntil",
    numeric: false,
    disablePadding: false,
    label: "Effective Until",
  },

  {
    id: "timeTo",
    numeric: false,
    disablePadding: false,
    label: "Time To",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: false,
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const BookingsTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [bookings, setBookings] = React.useState([]);
  const [parkings, setParkings] = React.useState([]);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state);

  React.useEffect(() => {
    bookingsCollection.get().then((response) => {
      setBookings(
        response.docs
          .map((el) => ({ bookingId: el.id, ...el.data() }))
          .filter((el) => el.userId === currentUser.userId)
          .sort(
            (a, b) =>
              new Date(b.effectiveUntil + " " + b.timeTo).getTime() -
              new Date(a.effectiveUntil + " " + a.timeTo).getTime()
          )
      );
    });

    parkingsCollection.get().then((response) => {
      setParkings(
        response.docs.map((el) => {
          return { ...el.data(), id: el.id };
        })
      );
    });
  }, [currentUser.userId]);

  const getParking = (id) => {
    return parkings.find((parking) => parking.id === id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = bookings.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setRowBackground = (row) => {
    if (
      new Date(row.effectiveFrom + " " + row.timeFrom).getTime() <=
        new Date().getTime() &&
      new Date(row.effectiveUntil + " " + row.timeTo).getTime() >=
        new Date().getTime()
    ) {
      return {
        background: "rgba(0,0,255,0.1)",
      };
    } else if (
      new Date(row.effectiveUntil + " " + row.timeTo).getTime() <
      new Date().getTime()
    ) {
      return {
        background: "rgba(0,0,0,0.1)",
      };
    }
    return {};
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookings.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={bookings.length}
            />
            <TableBody>
              {stableSort(bookings, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      style={setRowBackground(row)}
                    >
                      <TableCell padding="checkbox"></TableCell>

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {currentUser.firstName} {currentUser.surname}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.vehicleRegistration}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {getParking(row.parkingId)?.parkingName}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.spaceId}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.effectiveFrom}
                      </TableCell>

                      <TableCell align="left">{row.timeFrom}</TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.effectiveUntil}
                      </TableCell>

                      <TableCell align="left">{row.timeTo}</TableCell>
                      <TableCell align="left">
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() =>
                              dispatch(
                                openModal(
                                  "delete",
                                  "booking",
                                  "bookings",
                                  row.bookingId
                                )
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {/*
                      <TableCell align="left">{row.SpaceNo}</TableCell>
                      <TableCell align="left">{row.carParkName}</TableCell>
                      <TableCell align="left">{row.effectiveFrom}</TableCell>
                      <TableCell align="left">{row.timeFrom}</TableCell>
                      <TableCell align="left">{row.effectiveUntil}</TableCell>
                      <TableCell align="left">{row.timeTo}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={bookings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default BookingsTable;
