import React from "react";

import Heading from "../../components/atoms/Heading";
import parkingImg from "../../assets/images/Carpark.JPG";
import { AboutSectionWrapper, LeftCol } from "./styles/StyledAboutSection";

const AboutSection = () => {
  return (
    <AboutSectionWrapper>
      <LeftCol>
        <Heading headingType="h2">
          A solution to manage your car park effortlessly.
        </Heading>
        <p className="col-desc">
          A self-service portal for one or more organisations using your car
          park.
        </p>
      </LeftCol>
      <img src={parkingImg} alt="parking" className="about-img" />
    </AboutSectionWrapper>
  );
};

export default AboutSection;
