import * as Yup from "yup";

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Enter your email"),
  password: Yup.string()
    .required("Enter password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 char, one uppercase, one number, one special char"
    ),

  //acceptTerms: Yup.bool().oneOf([true], "You must accept terms"),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  reset_email: Yup.string().email("Invalid email").required("Enter your email"),
});

export const registerFormValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Enter your email"),
  password: Yup.string()
    .required("Enter password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 char, one uppercase, one number, one special char"
    ),

  vehicleRegistration: Yup.string().required("Enter vehicle registration"),

  confirmPassword: Yup.string()
    .required("Confirm password")
    .when("password", {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match"),
    }),

  acceptTerms: Yup.bool().oneOf([true], "You must accept terms"),
});

export const registerCarParkOwnerFormValidationSchema = Yup.object().shape({
  parkingName: Yup.string().required(),
  companyName: Yup.string().required(),
  parkingSpaces: Yup.string().required(),
  carParkAddress: Yup.string().required(),
  town: Yup.string().required(),
  postcode: Yup.string().required(),
  county: Yup.string().required(),
  contactNo: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const contactUsFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter firstname"),
  surname: Yup.string().required("Enter surname"),
  email: Yup.string().email().required("Enter email"),
  message: Yup.string().required("Enter message"),
});

export const sendReportFormValidationSchema = Yup.object().shape({
  carPark: Yup.string().required("Car Park Name required"),
  vehicleRegistration: Yup.string().required("Enter Vehicle Registration"),
  description: Yup.string().required("Report message required"),
});

export const accountFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter firstname"),
  surname: Yup.string().required("Enter surname"),
  activeVehicleRegistration: Yup.string().required(
    "Enter active Vehicle Registration"
  ),
});

export const bookingValidationSchema = Yup.object().shape({
  spaceNo: Yup.number().required("Space number is requierd!"),
});
