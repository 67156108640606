import styled from "styled-components";
import React from "react";
import { parkingData } from "./parkingData";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/actions";

const ParkingWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`;

const Space = styled.button`
  border: 2px solid black;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
  background-color: ${({ isBooked }) => (isBooked ? "gray" : "lightgreen")};
  color: white;
  cursor: pointer;
`;

const ParkingMap = () => {
  const dispatch = useDispatch();

  const selectedParking = "ealing";

  return (
    <ParkingWrapper>
      {parkingData[selectedParking].parkingSpaces.map((space) => (
        <Space
          onClick={() => dispatch(openModal("bookSpace"))}
          //   disabled={space.bookingData ? true : false}
          //   isBooked={space.bookingData}
        >
          <h3>{space.spaceNumber}</h3>
        </Space>
      ))}
    </ParkingWrapper>
  );
};

export default ParkingMap;
