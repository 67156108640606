import React from "react";
import Heading from "../../components/atoms/Heading";
import AboutSection from "./AboutSection";
import AboutSectionWithIcons from "./AboutSectionWithIcons";

const Home = () => {
  return (
    <>
      {/* <Heading content="Book your space now" headingType="h1" /> */}
      <Heading headingType="h1">
        Book your space now
      </Heading>
      <AboutSection />
      <AboutSectionWithIcons />
    </>
  );
};

export default Home;
