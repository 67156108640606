export const mainTheme = {
  colors: {
    main: "rgb(42,82,207)",
    darkBlue: "#033076",
    secondary: "rgb(141,212,111)",
    btnContent: "white",
    redDelete: "red",
    greenEdit: "green",
    grey: "grey",
  },
  fontSizes: {
    xs: "10px",
    s: "12px",
    m: "14px",
    l: "16px",
    xl: "26px",
    h1: "46px",
  },
  fontFamilly: {},

  devices: {
    mobileXs: "320px",
    mobile: "420px",
    tablet: "720",
    tabletXl: "1024px",
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 500,
    bold: 600,
  },
};
