import styled from "styled-components";
import { Field } from "formik";

export const StyledRegBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const RegBoxWrapper = styled.div`
  display: flex;
`;

export const RegBoxLabel = styled.label`
  display: flex;
  color: grey;
  font-size: small;
  justify-content: center;
`;

export const RegBoxFlag = styled.div`
  background-color: #233f92;
  color: #ffd800;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  gap: 3px;
  font-weight: bold;
  border-radius: 5px 0 0 5px;
  img {
    width: 25px;
  }
`;

export const RegisterNumberInput = styled(Field)`
  background-color: #ffd800;
  border: none;
  padding: 10px;
  font-family: "UKNumberPlate";
  border: 1px solid #12214e;
  border-left: none;
  font-size: 29px;
  outline: none;
  width: 190px;
  border-radius: 0 5px 5px 0;
`;
