import React from "react";
import Modal from "../../components/atoms/Modal";

const Register = () => {
  return (
    <div>
      <h1>Register</h1>
      <Modal />
    </div>
  );
};

export default Register;
