import Button from "../Button";
import React from "react";
import { mainTheme } from "../../../globalStyles/themes/mainTheme";
import { useDispatch } from "react-redux";
import { openModal } from "../../../redux/actions";
import { MdQueue } from "react-icons/md";
const AddButton = ({
  children,
  bgColor = mainTheme.colors.redDelete,
  onClick,
  type,
}) => {
  const dispatch = useDispatch();

  return (
    <Button
      bgColor={mainTheme.colors.darkBlue}
      onClick={() => dispatch(openModal("registerCarPark"))}
    >
      <MdQueue size={20} />
      Add
    </Button>
  );
};

export default AddButton;
