import React, { useState } from "react";
import RegisterCarParkOwnerForm from "../../components/forms/RegisterCarParkOwnerForm";

import RegisterCarParkForm from "../../components/forms/RegisterCarParkForm";
import { Formik } from "formik";
import { registerCarParkOwnerFormValidationSchema } from "../../utils/validationSchema";
import { useSelector } from "react-redux";
import { parkingsCollection } from "../../firebase/firestoreUtils";

const RegisterCarParkOwner = () => {
  const [next, setNext] = useState(false);
  const { currentUser } = useSelector((state) => state);

  return (
    <div>
      {/* <PageTitle>New Car Park Registration </PageTitle> */}

      <Formik
        initialValues={{
          carParkAddress: "",
          companyName: "",
          contactNo: "",
          county: "",
          created_date: new Date().getTime(),
          email: "",
          parkingName: "",
          parkingSpaces: "",
          postcode: "",
          status: currentUser?.role === "user" ? "unactive" : "active",
          town: "",
          userId: currentUser?.userId,
        }}
        validationSchema={registerCarParkOwnerFormValidationSchema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);

          parkingsCollection
            .doc()
            .set({
              ...values,
              adminId: "",
            })
            .then((response) => {
              resetForm();
              setNext(false);
            })
            .then(() => window.location.reload());
        }}
      >
        {!next ? (
          <RegisterCarParkForm setNext={setNext} />
        ) : (
          <RegisterCarParkOwnerForm setNext={setNext} />
        )}
      </Formik>
    </div>
  );
};

export default RegisterCarParkOwner;
